import { Controller } from '@hotwired/stimulus'
import IMask from 'imask'

export default class MaskController extends Controller {
  static values = {
    radix: {
      type: String,
      default: '.',
    },
    mask: String,
  }

  connect() {
    this.mask = IMask(this.element, this.getMask(this.maskValue))
    this.element
      .closest('form')
      .addEventListener('submit', this.submit.bind(this))
  }

  disconnect() {
    if (this.mask) {
      this.mask.destroy()
    }
  }

  submit() {
    this.element.value = this.mask.unmaskedValue
  }

  getMask(mask) {
    const masks = {
      money: {
        lazy: false,
        mask: 'num €',
        blocks: {
          num: {
            mask: Number,
            min: 0,
            max: 100000,
            scale: 2,
            radix: this.radixValue,
            mapToRadix: [this.radixValue],
          },
        },
      },
      displacement: {
        lazy: false,
        mask: 'num cc',
        blocks: {
          num: {
            mask: Number,
            min: 0,
            max: 1000,
            scale: 2,
            radix: this.radixValue,
            mapToRadix: [this.radixValue],
          },
        },
      },
      horsePower: {
        lazy: false,
        mask: 'num CV',
        blocks: {
          num: {
            mask: Number,
            min: 0,
            max: 1000,
            scale: 2,
            radix: this.radixValue,
            mapToRadix: [this.radixValue],
          },
        },
      },
      weight: {
        lazy: false,
        mask: 'num Kg',
        blocks: {
          num: {
            mask: Number,
            min: 0,
            max: 1000,
            scale: 2,
            radix: this.radixValue,
            mapToRadix: [this.radixValue],
          },
        },
      },
      dimension: {
        lazy: false,
        mask: 'num mm',
        blocks: {
          num: {
            mask: Number,
            min: 0,
            max: 10000,
            scale: 2,
            radix: this.radixValue,
            mapToRadix: [this.radixValue],
          },
        },
      },
      phone: {
        mask: '+34 000 000 000',
        lazy: false,
      },
      documentNumber: {
        mask: [
          {
            mask: 'a0000000a',
            lazy: false,
          },
          {
            mask: '00000000a',
            lazy: false,
          },
        ],
        dispatch: (appended, dynamicMasked) => {
          const firstChar = dynamicMasked.value[0] ?? appended
          return dynamicMasked.compiledMasks['XYZ'.includes(firstChar) ? 0 : 1]
        },
        prepareChar: str => str.toUpperCase(),
      },
      postalCode: {
        mask: '00000',
        lazy: false,
      },
    }
    return masks[mask]
  }
}
