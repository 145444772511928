// JsFromRoutes CacheKey 09929945b1c10880f8eafda5d0d83928
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client'

export default {
  index: /* #__PURE__ */ definePathHelper('get', '/dashboard/ownership_types'),
  create: /* #__PURE__ */ definePathHelper('post', '/dashboard/ownership_types'),
  new: /* #__PURE__ */ definePathHelper('get', '/dashboard/ownership_types/new'),
  edit: /* #__PURE__ */ definePathHelper('get', '/dashboard/ownership_types/:id/edit'),
  update: /* #__PURE__ */ definePathHelper('patch', '/dashboard/ownership_types/:id'),
}
