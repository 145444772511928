// JsFromRoutes CacheKey 1d38eb100df1200f1cbb2a78fd7bba58
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client'

export default {
  index: /* #__PURE__ */ definePathHelper('get', '/dashboard/professions'),
  create: /* #__PURE__ */ definePathHelper('post', '/dashboard/professions'),
  new: /* #__PURE__ */ definePathHelper('get', '/dashboard/professions/new'),
  edit: /* #__PURE__ */ definePathHelper('get', '/dashboard/professions/:id/edit'),
  update: /* #__PURE__ */ definePathHelper('patch', '/dashboard/professions/:id'),
}
