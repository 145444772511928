// JsFromRoutes CacheKey 4ca01ba25a1947ab4b9e6bc6014f1820
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client'

export default {
  index: /* #__PURE__ */ definePathHelper('get', '/dashboard/marital_statuses'),
  create: /* #__PURE__ */ definePathHelper('post', '/dashboard/marital_statuses'),
  new: /* #__PURE__ */ definePathHelper('get', '/dashboard/marital_statuses/new'),
  edit: /* #__PURE__ */ definePathHelper('get', '/dashboard/marital_statuses/:id/edit'),
  update: /* #__PURE__ */ definePathHelper('patch', '/dashboard/marital_statuses/:id'),
}
