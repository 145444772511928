// JsFromRoutes CacheKey d04878113bbbba97bd7d7e547010d984
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client'

export default {
  index: /* #__PURE__ */ definePathHelper('get', '/dashboard/years'),
  create: /* #__PURE__ */ definePathHelper('post', '/dashboard/years'),
  new: /* #__PURE__ */ definePathHelper('get', '/dashboard/years/new'),
  edit: /* #__PURE__ */ definePathHelper('get', '/dashboard/years/:id/edit'),
  update: /* #__PURE__ */ definePathHelper('patch', '/dashboard/years/:id'),
}
