import { Controller } from '@hotwired/stimulus'
import IMask from 'imask'

export default class FinancialDataStartingDateMaskController extends Controller {
  connect() {
    const initialValue = this.element.value
    if (initialValue) {
      const [year, month] = initialValue.split('-')
      this.element.value = `${month}/${year}`
    }
    this.mask = IMask(this.element, this.getMask())
    this.element
      .closest('form')
      .addEventListener('submit', this.submit.bind(this))
  }

  disconnect() {
    if (this.mask) {
      this.mask.destroy()
    }
  }

  submit() {
    const date = this.mask.value
    const [month, year] = date.split('/')
    this.element.value = `${year}-${month}-01`
  }

  getMask() {
    return {
      mask: Date,
      pattern: 'm/y',
      blocks: {
        m: {
          mask: IMask.MaskedRange,
          from: 1,
          to: 12,
          maxLength: 2,
        },
        y: {
          mask: IMask.MaskedRange,
          from: 1950,
          to: new Date().getFullYear(),
          maxLength: 4,
        },
      },
      format: (date) => {
        const month = date.getMonth() + 1
        const monthFormatted = month < 10 ? `0${month}` : month
        const year = date.getFullYear()
        const dateFormatted = `${monthFormatted}/${year}`
        return dateFormatted
      },
      parse: (str) => {
        const monthYear = str.split('/')
        return new Date(monthYear[1], monthYear[0] - 1, 1)
      },
      lazy: false,
    }
  }
}
