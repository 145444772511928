// JsFromRoutes CacheKey 307d603fdcd4071ae237112ed2eeb3b4
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client'

export default {
  index: /* #__PURE__ */ definePathHelper('get', '/dashboard/street_types'),
  create: /* #__PURE__ */ definePathHelper('post', '/dashboard/street_types'),
  new: /* #__PURE__ */ definePathHelper('get', '/dashboard/street_types/new'),
  edit: /* #__PURE__ */ definePathHelper('get', '/dashboard/street_types/:id/edit'),
  update: /* #__PURE__ */ definePathHelper('patch', '/dashboard/street_types/:id'),
}
