// JsFromRoutes CacheKey ddec18e9c78de2deb1753f266d507e94
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client'

export default {
  index: /* #__PURE__ */ definePathHelper('get', '/dashboard/contract_types'),
  create: /* #__PURE__ */ definePathHelper('post', '/dashboard/contract_types'),
  new: /* #__PURE__ */ definePathHelper('get', '/dashboard/contract_types/new'),
  edit: /* #__PURE__ */ definePathHelper('get', '/dashboard/contract_types/:id/edit'),
  update: /* #__PURE__ */ definePathHelper('patch', '/dashboard/contract_types/:id'),
}
