// JsFromRoutes CacheKey a35a3efedcfb5d0bc48de79206db89cd
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client'

export default {
  index: /* #__PURE__ */ definePathHelper('get', '/dashboard/company_activities'),
  create: /* #__PURE__ */ definePathHelper('post', '/dashboard/company_activities'),
  new: /* #__PURE__ */ definePathHelper('get', '/dashboard/company_activities/new'),
  edit: /* #__PURE__ */ definePathHelper('get', '/dashboard/company_activities/:id/edit'),
  update: /* #__PURE__ */ definePathHelper('patch', '/dashboard/company_activities/:id'),
}
