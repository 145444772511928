// JsFromRoutes CacheKey e84bd9cc86e4923503abe28ab93e0302
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client'

export default {
  table: /* #__PURE__ */ definePathHelper('get', '/dashboard/executions/table'),
  index: /* #__PURE__ */ definePathHelper('get', '/dashboard/executions'),
  show: /* #__PURE__ */ definePathHelper('get', '/dashboard/executions/:id'),
}
