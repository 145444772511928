// JsFromRoutes CacheKey a03da6f304bd2ee19beba169e62d0d17
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client'

export default {
  table: /* #__PURE__ */ definePathHelper('get', '/dashboard/banks/table'),
  index: /* #__PURE__ */ definePathHelper('get', '/dashboard/banks'),
  create: /* #__PURE__ */ definePathHelper('post', '/dashboard/banks'),
  new: /* #__PURE__ */ definePathHelper('get', '/dashboard/banks/new'),
  edit: /* #__PURE__ */ definePathHelper('get', '/dashboard/banks/:id/edit'),
  update: /* #__PURE__ */ definePathHelper('patch', '/dashboard/banks/:id'),
}
