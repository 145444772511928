// JsFromRoutes CacheKey 17d7b8675cfde9d1e45ddd522d9f5608
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client'

export default {
  index: /* #__PURE__ */ definePathHelper('get', '/dashboard/genders'),
  create: /* #__PURE__ */ definePathHelper('post', '/dashboard/genders'),
  new: /* #__PURE__ */ definePathHelper('get', '/dashboard/genders/new'),
  edit: /* #__PURE__ */ definePathHelper('get', '/dashboard/genders/:id/edit'),
  update: /* #__PURE__ */ definePathHelper('patch', '/dashboard/genders/:id'),
}
